import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, Badge, Fade } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

import Report from '../components/Report';
import Title from '../components/Title';
import Smx from '../components/sections/Smx';
import OpenPdf from './document/OpenPdf';

const base64Encode = (str) => Base64.stringify(Utf8.parse(str));
const base64Decode = (base64) => Base64.parse(base64).toString(Utf8);

const ReportPage = forwardRef(({ ga4 }, ref) => {
  const { t } = useTranslation('reportPage');
  const location = useLocation();
  const { id, domain } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  useDeepCompareEffect(() => {
    async function getReport() {
      let reportId = id;
      try {
        setLoading(true);
        if (domain) {
          reportId = base64Encode(domain);
        }
        const response = await axios.get(`/api/HttpReportShow?report-id=${reportId}`);
        setReport(response.data);
        if (ga4?.gtag) {
          let gaCategory = 'found';
          if (Object.keys(response.data).length < 1) {
            gaCategory = 'notfound';
          }
          ga4.gtag('event', 'view_item', {
            items: [
              {
                item_name: base64Decode(reportId),
                item_category: gaCategory,
              },
            ],
          });
        }
      } catch (exc) {
        if (ga4?.gtag) {
          ga4.gtag('event', 'view_item', {
            items: [
              {
                item_name: base64Decode(reportId),
                item_category: 'exception',
              },
            ],
          });
        }
        setError(exc);
      } finally {
        setLoading(false);
      }
    }
    if (id || domain) {
      getReport();
    } else {
      if (ga4?.gtag) {
        ga4.gtag('event', 'view_item', {
          items: [
            {
              item_name: 'empty',
              item_category: 'empty',
            },
          ],
        });
      }
      setLoading(false);
      setReport(null);
    }
  }, [ga4, id, domain]);

  let content;

  if (!(id || domain)) {
    content = <Smx />;
  } else if (error) {
    content = <Alert variant="warning">{t('errorMessage')}</Alert>;
  } else if (!loading) {
    if (Object.keys(report).length < 1) {
      content = <Alert variant="warning">{t('noInfoMessage')}</Alert>;
    } else {
      content = <Report report={report} />;
    }
  }

  return (
    <main id="report">
      <div className="d-flex justify-content-between">
        <div>
          <Title loading={loading} />
        </div>
        {!loading && report && report.domain && (
          <div>
            <Badge style={{ fontSize: '1.5em' }}>{report.domain}</Badge>
          </div>
        )}
      </div>
      {!loading && report && report.domain && (
        <div className="d-flex justify-content-end my-2">
          <OpenPdf label={t('pdfButtonLabel')} />
        </div>
      )}

      {content && (
        <Fade in={!loading} appear>
          <div>{content}</div>
        </Fade>
      )}
      {location.search === '?debug=true' && report && (
        <pre>{JSON.stringify(report, undefined, 2)}</pre>
      )}
    </main>
  );
});

ReportPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ga4: PropTypes.object,
};

ReportPage.defaultProps = {
  ga4: undefined,
};

export default ReportPage;
