import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'checkDomain', function checkDomainWrapper(errorMessage) {
  return this.test('check-domain', errorMessage, function checkDomain(email) {
    const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(email);
    if (match) {
      const forbiddenDomains = [
        'apple.com',
        'me.com',
        'icloud.com',
        'mac.com',
        'yahoo.com',
        'xtra.com',
        'hotmail.com',
        'gmail.com',
      ];
      if (forbiddenDomains.indexOf(match[1].toLowerCase()) >= 0) {
        return this.createError({ errorMessage });
      }
      return email;
    }
    return true;
  });
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  email: Yup.string()
    .email('Enter a valid email address')
    .checkDomain('Email domain should be a company domain')
    .required('Please! Enter your email adress'),
  role: Yup.string().required('Role is required'),
  acceptTerms: Yup.bool().oneOf([true], 'Accept terms and conditions'),
  acceptContact: Yup.bool().oneOf([true], 'Accept permission to contact'),
  recaptcha: Yup.string().required('Recaptcha verification required'),
});

export default validationSchema;
