import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

import Circle from '../Circle';
import Metrics from '../Metrics';

function calculateLevel(score) {
  if (score.failType === 0) return Circle.LEVELS.DANGER;
  if (score.failType === 1) return Circle.LEVELS.WARNING;
  if (score.failType === 2) return Circle.LEVELS.GOOD;
  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <p>{t('sections.spfSection.content.description.dangerLevel.textNoValidSpf')}</p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {`${t('sections.spfSection.content.description.noDescription')} ${level}`}
            </a>
          </p>
        </div>
      );
    case Circle.LEVELS.WARNING:
      return (
        <p>{t('sections.spfSection.content.description.warningLevel.textDeployedSpf')}</p>
      );
    case Circle.LEVELS.GOOD:
      return <p>{t('sections.spfSection.content.description.goodLevel.textSpfValid')}</p>;
    default:
      return (
        <p>{`${t('sections.spfSection.content.description.noDescription')} ${level}`}</p>
      );
  }
}

function Spf(props) {
  const { score, record, warnings, passedLookups, error } = props;
  const { t } = useTranslation('reportComponent');
  const { t: translateMetrics } = useTranslation('metricsComponent');
  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);

  /* TODO too much vague, unsafe business logic here, should be on server side */
  const metrics = [
    {
      label: translateMetrics('labels.spfValid'),
      level: score.spfValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value:
        score.spfValid === 1
          ? translateMetrics('values.yes')
          : translateMetrics('values.no'),
    },
  ];

  if (score.spfValid === 1) {
    if (passedLookups) {
      metrics.push({
        label: translateMetrics('labels.includes#'),
        level: Circle.LEVELS.GOOD,
        value: translateMetrics('values.lookupsGood'),
      });
    } else {
      metrics.push({
        label: translateMetrics('labels.includes#'),
        level: Circle.LEVELS.DANGER,
        value: translateMetrics('values.lookupsBad'),
      });
    }
  }

  let spfPolicyLevel;
  let spfPolicyValue;
  if (score.failType === 0) {
    if (score.spfValid === 1) {
      spfPolicyLevel = Circle.LEVELS.GOOD;
      spfPolicyValue = translateMetrics('values.permissive');
    } else {
      spfPolicyLevel = Circle.LEVELS.DANGER;
      spfPolicyValue = translateMetrics('values.no');
    }
  } else if (score.failType === 1) {
    spfPolicyLevel = Circle.LEVELS.GOOD;
    spfPolicyValue = translateMetrics('values.permissive');
  } else if (score.failType === 2) {
    spfPolicyLevel = Circle.LEVELS.GOOD;
    spfPolicyValue = translateMetrics('values.permissive');
  } else {
    spfPolicyLevel = Circle.LEVELS.UNDEFINED;
    spfPolicyValue = translateMetrics('values.no');
  }

  metrics.push({
    label: translateMetrics('labels.spfPolicy'),
    level: spfPolicyLevel,
    value: spfPolicyValue,
  });

  return (
    <section>
      <p>{t('sections.spfSection.content.textSpfIntro')}</p>
      <p>{t('sections.spfSection.content.textSpf')}</p>
      <Card body border="light" bg="light">
        <Row>
          <Col xs={12} sm={4}>
            <Circle title="SPF" level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
      {record && (
        <p className="mt-3">
          <strong>{t('sections.spfSection.content.recordTitle')}</strong>
          &nbsp;
          {record}
        </p>
      )}
      {error && (
        <p className="mt-3">
          <strong>{t('sections.spfSection.content.errorTitle')}</strong>
          &nbsp;
          {error}
        </p>
      )}
      {warnings?.length > 0 && (
        <p className="mt-3">
          <strong>{t('sections.spfSection.content.warningsTitle')}</strong>
          &nbsp;
          {warnings.join(', ')}
        </p>
      )}
      <Metrics list={metrics} />
    </section>
  );
}

Spf.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  passedLookups: PropTypes.string,
  warnings: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.string,
  error: PropTypes.string,
};

Spf.defaultProps = {
  record: undefined,
  warnings: undefined,
  passedLookups: null,
  error: undefined,
};

Spf.calculateLevel = calculateLevel;

export default Spf;
