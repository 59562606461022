import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import resources from './resources';

const languageDetectorOptions = {
  order: [
    'localStorage',
    'querystring',
    'cookie',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
};

i18next
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    interpolation: { escapeValue: false },
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    nonExplicitSupportedLngs: true,
    debug: process.env.NODE_ENV === 'development',
    detection: languageDetectorOptions,
  });

export default i18next;
