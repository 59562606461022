import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

function Title(props) {
  const { loading } = props;
  const { t } = useTranslation('titleComponent');

  return (
    <h1>
      {t('title')}
      {loading && (
        <Spinner
          style={{ marginLeft: '1rem', verticalAlign: 'middle' }}
          animation="border"
          role="status"
          variant="info"
        />
      )}
    </h1>
  );
}

Title.propTypes = {
  loading: PropTypes.bool,
};

Title.defaultProps = {
  loading: false,
};

export default Title;
