import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import Styles from './Styles';

import Intro from './sections/Intro';
import Provider from './sections/Provider';
import Spf from './sections/Spf';
import Tls from './sections/Tls';
import Dmarc from './sections/Dmarc';
import Smx from './sections/Smx';
import Badge from './components/Badge';

function Report({ report }) {
  const { t: titleTranslation } = useTranslation('titleComponent');

  const { t } = useTranslation('reportComponent');
  return (
    <View style={Styles.view}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={Styles.bigTitle}>{titleTranslation('title')}</Text>
        <Badge domain={report.domain} />
      </View>
      <Text style={Styles.title}>
        <h4>{t('header')}</h4>
      </Text>
      <Intro report={report} />
      <View style={Styles.section}>
        <Text style={{ ...Styles.title, ...Styles.blueBackground }}>
          {t('sections.dmarcSection.title')}
        </Text>
        <Dmarc
          score={report.scores.dmarc}
          record={report.dmarc.record}
          warnings={report.dmarc.warnings}
          error={report.dmarc.error}
          tags={report.dmarc.tags}
        />
      </View>
      <View style={Styles.section} break>
        <Text style={{ ...Styles.title, ...Styles.blueBackground }}>
          {t('sections.spfSection.title')}
        </Text>
        <Spf
          score={report.scores.spf}
          record={report.spf.record}
          passedLookups={report.spf.passedLookups}
          warnings={report.spf.warnings}
          error={report.spf.error}
        />
      </View>
      <View style={Styles.section}>
        <Text style={{ ...Styles.title, ...Styles.blueBackground }}>
          {t('sections.providerSection.title')}
        </Text>
        <Provider score={report.scores.provider} />
      </View>
      <View style={Styles.section} break>
        <Text style={{ ...Styles.title, ...Styles.blueBackground }}>
          {t('sections.tlsSection.title')}
        </Text>
        <Tls score={report.scores.tls} warnings={report.mx.warnings} />
      </View>
      <View style={Styles.section}>
        <Text style={{ ...Styles.title, ...Styles.blueBackground }}>
          {t('sections.smxSection.title')}
        </Text>
        <Smx />
      </View>
    </View>
  );
}

Report.propTypes = {
  report: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Report;
