import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';

import Dmarc from './sections/Dmarc';
import Intro from './sections/Intro';
import Provider from './sections/Provider';
import Spf from './sections/Spf';
import Tls from './sections/Tls';
import Smx from './sections/Smx';

function Report(props) {
  const { report } = props;
  const { t } = useTranslation('reportComponent');
  return (
    <div>
      <h4>{t('header')}</h4>
      <Intro report={report} />
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header id="dmarcSection">
            {t('sections.dmarcSection.title')}
          </Accordion.Header>
          <Accordion.Body>
            <Dmarc
              score={report.scores.dmarc}
              record={report.dmarc.record}
              warnings={report.dmarc.warnings}
              error={report.dmarc.error}
              tags={report.dmarc.tags}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" flush style={{ pageBreakBefore: 'always' }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header id="spfSection">
            {t('sections.spfSection.title')}
          </Accordion.Header>
          <Accordion.Body>
            <Spf
              score={report.scores.spf}
              record={report.spf.record}
              passedLookups={report.spf.passedLookups}
              warnings={report.spf.warnings}
              error={report.spf.error}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header id="providerSection">
            {t('sections.providerSection.title')}
          </Accordion.Header>
          <Accordion.Body>
            <Provider score={report.scores.provider} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" flush style={{ pageBreakBefore: 'always' }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header id="tlsSection">
            {t('sections.tlsSection.title')}
          </Accordion.Header>
          <Accordion.Body>
            <Tls score={report.scores.tls} warnings={report.mx.warnings} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <strong> {t('sections.smxSection.title')}</strong>
          </Accordion.Header>
          <Accordion.Body>
            <Smx />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

Report.propTypes = {
  report: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Report;
