import { useTranslation } from 'react-i18next';

function Smx(props) {
  const { t } = useTranslation('reportComponent');
  return (
    <>
      <p>{t('sections.smxSection.content.introduction')}</p>
      <h3>{t('sections.smxSection.content.trustSection.header')}</h3>
      <p>
        <a target="_blank" rel="noreferrer" href="https://smxemail.com/our-company/">
          {t('sections.smxSection.content.trustSection.linkLabels.more')}
        </a>
      </p>
      <h3>{t('sections.smxSection.content.enhanceSection.header')}</h3>

      <p>{t('sections.smxSection.content.enhanceSection.content.p1')}</p>

      <p>{t('sections.smxSection.content.enhanceSection.content.p2')}</p>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://smxemail.com/media/byzjibji/datasheet-smx-365_august2021.pdf"
        >
          {t('sections.smxSection.content.enhanceSection.linkLabels.download')}
        </a>
      </p>
      <h3>{t('sections.smxSection.content.protectSection.header')}</h3>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/"
        >
          {t('sections.smxSection.content.protectSection.linkLabels.book')}
        </a>
      </p>
    </>
  );
}

export default Smx;
