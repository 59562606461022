import React from 'react';
import { capitalCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Text, View, Link } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import Styles from '../Styles';

import Circle from '../components/Circle';
import Metrics from '../components/Metrics';

function calculateLevel(score) {
  if (score.dmarcValid === 0) return Circle.LEVELS.DANGER;
  if (score.spfValid === 0) return Circle.LEVELS.DANGER;
  if (score.dmarcPolicy === 1) return Circle.LEVELS.WARNING;
  if (score.dmarcPolicy === 3) return Circle.LEVELS.GOOD;

  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <View style={Styles.section}>
          <Text style={Styles.smallText}>
            {t('sections.dmarcSection.content.description.dangerLevel.textNoDmarc')}
          </Text>
          <Text>
            {t('sections.dmarcSection.content.description.dangerLevel.textgoodNews')}{' '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/our-company/blogs-news/blog/email-comes-of-age-with-dmarc/"
            >
              {t('sections.dmarcSection.content.description.dangerLevel.linkInfo')}{' '}
            </Link>
          </Text>
        </View>
      );
    case Circle.LEVELS.WARNING:
      return (
        <View style={Styles.section}>
          <Text style={Styles.smallText}>
            {t('sections.dmarcSection.content.description.warningLevel.textBegunDmarc')}
          </Text>
        </View>
      );
    case Circle.LEVELS.GOOD:
      return (
        <Text style={Styles.smallText}>
          {t('sections.dmarcSection.content.description.goodLevel.textWellDoneDmarc')}
        </Text>
      );
    default:
      return (
        <Text style={Styles.text}>
          {' '}
          {`${t('sections.dmarcSection.content.description.noDescription')} ${level}`}
        </Text>
      );
  }
}

function Dmarc(props) {
  const { score, record, warnings, error, tags } = props;
  const { t } = useTranslation('reportComponent');
  const { t: translateMetrics } = useTranslation('metricsComponent');
  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);

  /* TODO too much vague, unsafe business logic here, should be on server side */
  const metrics = [
    {
      label: translateMetrics('labels.dmarcValid'),
      level: score.dmarcValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value:
        score.dmarcValid === 1
          ? translateMetrics('values.yes')
          : translateMetrics('values.no'),
    },
    {
      label: translateMetrics('labels.spfValid'),
      level: score.spfValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value:
        score.spfValid === 1
          ? translateMetrics('values.yes')
          : translateMetrics('values.no'),
    },
  ];

  if (tags?.p) {
    metrics.push({
      label: translateMetrics('labels.dmarcPolicy'),
      level: tags.p.value === 'none' ? Circle.LEVELS.WARNING : Circle.LEVELS.GOOD,
      value: capitalCase(translateMetrics(`values.${tags.p.value}`)),
    });
  } else {
    metrics.push({
      label: translateMetrics('labels.dmarcPolicy'),
      level: Circle.LEVELS.WARNING,
      value: translateMetrics('values.no'),
    });
  }
  if (tags?.rua) {
    metrics.push({
      label: translateMetrics('labels.dmarcReporting'),
      level: Circle.LEVELS.GOOD,
      value: translateMetrics('values.yes'),
    });
  } else {
    metrics.push({
      label: translateMetrics('labels.dmarcReporting'),
      level: Circle.LEVELS.WARNING,
      value: translateMetrics('values.no'),
    });
  }

  return (
    <View style={Styles.leftMargin}>
      <View style={Styles.section}>
        <Text style={Styles.text}>
          {t('sections.dmarcSection.content.textDmarcStopsSpoofing')}
        </Text>
      </View>
      <View style={Styles.flexContainer}>
        <View>
          <Circle title="DMARC" level={level} />
        </View>
        <View style={Styles.flexSectionRight}>{description}</View>
      </View>
      <View style={Styles.section}>
        {record && (
          <>
            <Text style={Styles.bold}>
              {t('sections.dmarcSection.content.recordTitle')}{' '}
            </Text>
            <Text style={Styles.smallText}>{record}</Text>
          </>
        )}
        {error && (
          <>
            <Text style={Styles.bold}>
              {t('sections.dmarcSection.content.errorTitle')}{' '}
            </Text>
            <Text style={Styles.smallText}>{error}</Text>
          </>
        )}
        {warnings?.length > 0 && (
          <>
            <Text style={Styles.bold}>
              {t('sections.dmarcSection.content.warningsTitle')}{' '}
            </Text>
            <Text style={Styles.smallText}>{warnings.join(', ')}</Text>
          </>
        )}
        <Metrics list={metrics} />
      </View>
    </View>
  );
}

Dmarc.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.string,
  error: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.object,
};

Dmarc.defaultProps = {
  record: undefined,
  warnings: undefined,
  error: undefined,
  tags: undefined,
};

Dmarc.calculateLevel = calculateLevel;

export default Dmarc;
