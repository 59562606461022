import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

import Circle from '../Circle';

function calculateLevel(score) {
  if (score.error) return Circle.LEVELS.DANGER;
  if (!score.starttls && !score.count) return Circle.LEVELS.DANGER;
  if (!score.starttls) return Circle.LEVELS.WARNING;
  if (!score.count) return Circle.LEVELS.WARNING;
  if (score.starttls && score.count) return Circle.LEVELS.GOOD;

  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <p>
            {t('sections.tlsSection.content.description.dangerLevel.textNoSupportTLS')}
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {t('sections.tlsSection.content.description.dangerLevel.linkHelp')}
            </a>
          </p>
        </div>
      );
    case Circle.LEVELS.WARNING:
      return (
        <p>
          {t(
            'sections.tlsSection.content.description.warningLevel.textPariallyProtectedTLS'
          )}
        </p>
      );
    case Circle.LEVELS.GOOD:
      return (
        <p>
          {t('sections.tlsSection.content.description.goodLevel.textFullySecuredTLS')}
        </p>
      );
    default:
      return (
        <p>{`${t('sections.tlsSection.content.description.noDescription')} ${level}`}</p>
      );
  }
}

const renderWarnings = (warnings, t) => {
  if (warnings?.length > 0) {
    const filteredWarnings = warnings.filter(
      (w) => !w.startsWith(t('sections.tlsSection.content.warnings.startsWith'))
    );
    if (filteredWarnings.length > 0) {
      return (
        <p className="mt-3">
          <strong>{t('sections.tlsSection.content.warnings.title')}</strong>{' '}
          {filteredWarnings.join(', ')}
        </p>
      );
    }
    return undefined;
  }
  return undefined;
};

function Tls(props) {
  const { score, warnings } = props;
  const { t } = useTranslation('reportComponent');
  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);
  return (
    <section>
      <p>{t('sections.tlsSection.content.textTLSIntro')}</p>
      <Card body border="light" bg="light">
        <Row>
          <Col xs={12} sm={4}>
            <Circle title="TLS" level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
      {renderWarnings(warnings, t)}
    </section>
  );
}

Tls.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Tls.calculateLevel = calculateLevel;

export default Tls;
