import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

const LEVELS = {
  GOOD: 'good',
  WARNING: 'warning',
  DANGER: 'danger',
  UNDEFINED: 'undefined',
};

function Circle(props) {
  const { title, level, radius } = props;

  return (
    <div
      className={classnames('circle', level)}
      style={{ width: radius * 2, height: radius * 2 }}
    >
      <h4>{title}</h4>
    </div>
  );
}

Circle.propTypes = {
  title: PropTypes.string,
  level: PropTypes.oneOf(Object.values(LEVELS)),
  radius: PropTypes.number,
};

Circle.defaultProps = {
  radius: 72,
  level: 'undefined',
  title: 'undefined',
};

Circle.LEVELS = LEVELS;

export default Circle;
