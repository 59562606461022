import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

import Circle from '../Circle';

function calculateLevel(score) {
  switch (score.trustedProvider) {
    case 0:
      return Circle.LEVELS.DANGER;
    case 1:
      return Circle.LEVELS.WARNING;
    case 2:
      return Circle.LEVELS.GOOD;
    default:
      return Circle.LEVELS.UNDEFINED;
  }
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <>
          <p>
            {t(
              'sections.providerSection.content.description.dangerLevel.textNotUsing3rdPartySEG'
            )}
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {t('sections.providerSection.content.description.dangerLevel.linkHelp')}
            </a>
          </p>
        </>
      );
    case Circle.LEVELS.WARNING:
      return (
        <>
          <p>
            {t(
              'sections.providerSection.content.description.warningLevel.textUsing3rdParty'
            )}
          </p>
          <p>
            {t(
              'sections.providerSection.content.description.warningLevel.textManyOrganisations'
            )}
          </p>
          <p>
            {t(
              'sections.providerSection.content.description.warningLevel.linkMoreInfo.titleInfo'
            )}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-for-enterprise/smx-365/"
            >
              {t(
                'sections.providerSection.content.description.warningLevel.linkMoreInfo.linkSmx365'
              )}
            </a>
          </p>
        </>
      );
    case Circle.LEVELS.GOOD:
      return (
        <p>
          {t(
            'sections.providerSection.content.description.goodLevel.textEmailsProtected'
          )}
        </p>
      );
    default:
      return (
        <p>
          {`${t('sections.providerSection.content.description.noDescription')} ${level}`}
        </p>
      );
  }
}

function Provider(props) {
  const { score } = props;
  const { t } = useTranslation('reportComponent');
  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);

  return (
    <section>
      <p>{t('sections.providerSection.content.textImplementingSEG')}</p>
      <Card body border="light" bg="light">
        <Row>
          <Col xs={12} sm={4}>
            <Circle title="Provider" level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
    </section>
  );
}

Provider.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
};

Provider.calculateLevel = calculateLevel;

export default Provider;
