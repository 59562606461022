import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from '../Styles';

const Styles = styles.circle;

const LEVELS = {
  GOOD: 'good',
  WARNING: 'warning',
  DANGER: 'danger',
  UNDEFINED: 'undefined',
};

function Circle({ title, level, radius }) {
  return (
    <View
      style={{
        width: radius * 1.5,
        height: radius * 1.5,
        ...Styles.circle,
        ...Styles[level],
      }}
    >
      <Text style={Styles.title}>{title}</Text>
    </View>
  );
}

Circle.propTypes = {
  title: PropTypes.string,
  level: PropTypes.oneOf(Object.values(LEVELS)),
  radius: PropTypes.number,
};

Circle.defaultProps = {
  radius: 72,
  level: 'undefined',
  title: 'undefined',
};

Circle.LEVELS = LEVELS;

export default Circle;
