import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, Link } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import Styles from '../Styles';

import Circle from '../components/Circle';
import Metrics from '../components/Metrics';

function calculateLevel(score) {
  if (score.failType === 0) return Circle.LEVELS.DANGER;
  if (score.failType === 1) return Circle.LEVELS.WARNING;
  if (score.failType === 2) return Circle.LEVELS.GOOD;

  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <View>
          <Text style={Styles.smallText}>
            {t('sections.spfSection.content.description.dangerLevel.textNoValidSpf')}
          </Text>
          <Text>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {t('sections.spfSection.content.description.dangerLevel.linkHelp')}{' '}
            </Link>
          </Text>
        </View>
      );
    case Circle.LEVELS.WARNING:
      return (
        <Text style={Styles.smallText}>
          {t('sections.spfSection.content.description.warningLevel.textDeployedSpf')}
        </Text>
      );
    case Circle.LEVELS.GOOD:
      return (
        <Text style={Styles.smallText}>
          {t('sections.spfSection.content.description.goodLevel.textSpfValid')}
        </Text>
      );
    default:
      return (
        <Text style={Styles.text}>
          {`${t('sections.spfSection.content.description.noDescription')} ${level}`}
        </Text>
      );
  }
}

function Spf(props) {
  const { score, record, warnings, passedLookups, error } = props;
  const { t } = useTranslation('reportComponent');
  const { t: translateMetrics } = useTranslation('metricsComponent');
  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);

  /* TODO too much vague, unsafe business logic here, should be on server side */
  const metrics = [
    {
      label: translateMetrics('labels.spfValid'),
      level: score.spfValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value:
        score.spfValid === 1
          ? translateMetrics('values.yes')
          : translateMetrics('values.no'),
    },
  ];

  if (score.spfValid === 1) {
    if (passedLookups) {
      metrics.push({
        label: translateMetrics('labels.includes#'),
        level: Circle.LEVELS.GOOD,
        value: translateMetrics('values.lookupsGood'),
      });
    } else {
      metrics.push({
        label: translateMetrics('labels.includes#'),
        level: Circle.LEVELS.DANGER,
        value: translateMetrics('values.lookupsBad'),
      });
    }
  }

  let spfPolicyLevel;
  let spfPolicyValue;
  if (score.failType === 0) {
    if (score.spfValid === 1) {
      spfPolicyLevel = Circle.LEVELS.GOOD;
      spfPolicyValue = translateMetrics('values.permissive');
    } else {
      spfPolicyLevel = Circle.LEVELS.DANGER;
      spfPolicyValue = translateMetrics('values.no');
    }
  } else if (score.failType === 1) {
    spfPolicyLevel = Circle.LEVELS.GOOD;
    spfPolicyValue = translateMetrics('values.permissive');
  } else if (score.failType === 2) {
    spfPolicyLevel = Circle.LEVELS.GOOD;
    spfPolicyValue = translateMetrics('values.permissive');
  } else {
    spfPolicyLevel = Circle.LEVELS.UNDEFINED;
    spfPolicyValue = translateMetrics('values.no');
  }

  metrics.push({
    label: translateMetrics('labels.spfPolicy'),
    level: spfPolicyLevel,
    value: spfPolicyValue,
  });

  return (
    <View style={Styles.leftMargin}>
      <View style={Styles.section}>
        <Text style={Styles.text}>{t('sections.spfSection.content.textSpfIntro')}</Text>
        <Text style={Styles.text}>{t('sections.spfSection.content.textSpf')}</Text>
      </View>
      <View style={Styles.flexContainer}>
        <View>
          <Circle title="SPF" level={level} />
        </View>
        <View style={Styles.flexSectionRight}>{description}</View>
      </View>
      <View style={Styles.section}>
        {record && (
          <>
            <Text style={Styles.bold}>
              {t('sections.spfSection.content.recordTitle')}
            </Text>
            <Text style={Styles.smallText}>{record}</Text>
          </>
        )}
        {error && (
          <>
            <Text style={Styles.bold}>{t('sections.spfSection.content.errorTitle')}</Text>
            <Text style={Styles.smallText}>{error}</Text>
          </>
        )}
        {warnings?.length > 0 && (
          <>
            <Text style={Styles.bold}>
              {t('sections.spfSection.content.warningsTitle')}
            </Text>
            <Text>{warnings.join(', ')}</Text>
          </>
        )}
        <Metrics list={metrics} />
      </View>
    </View>
  );
}

Spf.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  passedLookups: PropTypes.string,
  warnings: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.string,
  error: PropTypes.string,
};

Spf.defaultProps = {
  record: undefined,
  warnings: undefined,
  passedLookups: null,
  error: undefined,
};

Spf.calculateLevel = calculateLevel;

export default Spf;
