import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, Link } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import Styles from '../Styles';
import Circle from '../components/Circle';

function calculateLevel(score) {
  if (score.error) return Circle.LEVELS.DANGER;
  if (!score.starttls && !score.count) return Circle.LEVELS.DANGER;
  if (!score.starttls) return Circle.LEVELS.WARNING;
  if (!score.count) return Circle.LEVELS.WARNING;
  if (score.starttls && score.count) return Circle.LEVELS.GOOD;

  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <View>
          <Text style={Styles.smallText}>
            {t('sections.tlsSection.content.description.dangerLevel.textNoSupportTLS')}
          </Text>
          <Text style={Styles.smallText}>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {t('sections.tlsSection.content.description.dangerLevel.linkHelp')}
            </Link>
          </Text>
        </View>
      );
    case Circle.LEVELS.WARNING:
      return (
        <Text style={Styles.smallText}>
          {t(
            'sections.tlsSection.content.description.warningLevel.textPariallyProtectedTLS'
          )}
        </Text>
      );
    case Circle.LEVELS.GOOD:
      return (
        <Text style={Styles.smallText}>
          {t('sections.tlsSection.content.description.goodLevel.textFullySecuredTLS')}
        </Text>
      );
    default:
      return (
        <Text style={Styles.text}>{`${t(
          'sections.tlsSection.content.description.noDescription'
        )} ${level}`}</Text>
      );
  }
}

const renderWarnings = (warnings, t) => {
  if (warnings?.length > 0) {
    const filteredWarnings = warnings.filter(
      (w) => !w.startsWith(t('sections.tlsSection.content.warnings.startsWith'))
    );
    if (filteredWarnings.length > 0) {
      return (
        <>
          <Text style={Styles.bold}>
            {t('sections.tlsSection.content.warnings.title')}
          </Text>
          <Text style={Styles.smallText}>{filteredWarnings.join(', ')}</Text>
        </>
      );
    }
    return undefined;
  }
  return undefined;
};

function Tls({ score, warnings }) {
  const { t } = useTranslation('reportComponent');
  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);
  return (
    <View style={Styles.leftMargin}>
      <View style={Styles.section}>
        <Text style={Styles.text}>{t('sections.tlsSection.content.textTLSIntro')}</Text>
      </View>
      <View style={Styles.flexContainer}>
        <View>
          <Circle title="TLS" level={level} />
        </View>
        <View style={Styles.flexSectionRight}>{description}</View>
      </View>
      <View style={Styles.section}>{renderWarnings(warnings, t)}</View>
    </View>
  );
}

Tls.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Tls.calculateLevel = calculateLevel;

export default Tls;
