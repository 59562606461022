import React from 'react';
import { View, Svg, Circle, Image, G, Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import needle from '../assets/needle.png';
import styles from '../Styles';

const Styles = styles.gauge;

function Gauge({ final }) {
  const angle = (final * 180) / 100 - 90;

  return (
    <View style={Styles.wrapper}>
      <Svg style={Styles.meter} viewBox="-180 0 0 1">
        <G transform="scale(0.5) translate(-160,50)">
          <Circle style={Styles.outLineEnds} r="150" cx="50%" cy="50%" />
          <Circle style={Styles.outLineGray} r="150" cx="50%" cy="50%" />
          <Circle style={Styles.outLineGood} r="150" cx="50%" cy="50%" />
          <Circle style={Styles.outLineWarning} r="150" cx="50%" cy="50%" />
          <Circle style={Styles.outLineDanger} r="150" cx="50%" cy="50%" />
          <Text style={Styles.percentage} x="-35" y="50">{`${final} %`}</Text>
        </G>
      </Svg>
      <Image
        style={{ ...Styles.needle, transform: `rotate(${angle}deg)` }}
        src={needle}
      />
    </View>
  );
}

Gauge.propTypes = {
  final: PropTypes.number.isRequired,
};

export default Gauge;
