import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View, Link } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import Styles from '../Styles';

import Circle from '../components/Circle';

function calculateLevel(score) {
  switch (score.trustedProvider) {
    case 0:
      return Circle.LEVELS.DANGER;
    case 1:
      return Circle.LEVELS.WARNING;
    case 2:
      return Circle.LEVELS.GOOD;
    default:
      return Circle.LEVELS.UNDEFINED;
  }
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <View>
          <Text style={Styles.smallText}>
            {t(
              'sections.providerSection.content.description.dangerLevel.textNotUsing3rdPartySEG'
            )}
          </Text>
          <Text>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {t('sections.providerSection.content.description.dangerLevel.linkHelp')}{' '}
            </Link>
          </Text>
        </View>
      );
    case Circle.LEVELS.WARNING:
      return (
        <View>
          <Text style={Styles.smallText}>
            {t(
              'sections.providerSection.content.description.warningLevel.textUsing3rdParty'
            )}
          </Text>
          <Text style={Styles.smallText}>
            {t(
              'sections.providerSection.content.description.warningLevel.textManyOrganisations'
            )}
          </Text>
          <Text style={Styles.smallText}>
            {t(
              'sections.providerSection.content.description.warningLevel.linkMoreInfo.titleInfo'
            )}{' '}
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-for-enterprise/smx-365/"
            >
              {t(
                'sections.providerSection.content.description.warningLevel.linkMoreInfo.linkSmx365'
              )}
            </Link>
          </Text>
        </View>
      );
    case Circle.LEVELS.GOOD:
      return (
        <Text style={Styles.smallText}>
          {t(
            'sections.providerSection.content.description.goodLevel.textEmailsProtected'
          )}
        </Text>
      );
    default:
      return (
        <Text style={Styles.text}>{`${t(
          'sections.providerSection.content.description.noDescription'
        )} ${level}`}</Text>
      );
  }
}

function Provider({ score }) {
  const level = calculateLevel(score);
  const { t } = useTranslation('reportComponent');
  const description = getLevelDescription(level, t);
  return (
    <View style={Styles.leftMargin}>
      <View style={Styles.section}>
        <Text style={Styles.text}>
          <p>{t('sections.providerSection.content.textImplementingSEG')}</p>
        </Text>
      </View>
      <View style={Styles.flexContainer}>
        <View>
          <Circle title="Provider" level={level} />
        </View>
        <View style={Styles.flexSectionRight}>{description}</View>
      </View>
    </View>
  );
}

Provider.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
};

Provider.calculateLevel = calculateLevel;

export default Provider;
