import React from 'react';

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.67 88.93">
      <linearGradient
        id="color-logo"
        y1="44.46"
        x2="281.67"
        y2="44.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff173b" />
        <stop offset="1" stopColor="#006497" />
      </linearGradient>
      <path
        className="color-logo"
        d="M281.67,87.8H248.11l-9.18-10.87q-.45-.63-5.44-6.66t-6.62-8.22q-7.5,10.13-12.25,16l-7.94,9.75H173.62l36.75-45.06L175.31,1.19h33.37l5.94,7.25q5.12,6.3,12.25,15.74,6.81-10,10.87-14.81l6.81-8.18H277.3L243.61,42.74Zm-112,0H143.56V37.62L120,66.68h-2.07L94.31,37.62V87.8H68.94V1.19H92.63l26.62,32.49L146,1.19h23.69ZM64,62.62a23.91,23.91,0,0,1-4.15,14.06,26.23,26.23,0,0,1-11.6,9.09,43.37,43.37,0,0,1-17.06,3.16q-2.68,0-6.59-.35a74.65,74.65,0,0,1-10.37-1.9A82.28,82.28,0,0,1,.75,82.05V60.8a94.83,94.83,0,0,0,12.9,6.75,34.35,34.35,0,0,0,13.6,2.69c4.45,0,7.34-.74,8.65-2.22a6.5,6.5,0,0,0,2-4.15A7.08,7.08,0,0,0,34.5,58a48.15,48.15,0,0,0-9.82-5,76.89,76.89,0,0,1-12.49-6.53,29.48,29.48,0,0,1-8.78-8.84A22.77,22.77,0,0,1,0,25.06,23.91,23.91,0,0,1,3.44,12.4a23.88,23.88,0,0,1,10.5-9Q21,0,31.18,0A60.05,60.05,0,0,1,44.59,1.41,57.86,57.86,0,0,1,54.74,4.56a40.28,40.28,0,0,1,5.5,2.75V27.62A71.44,71.44,0,0,0,48.4,20.81a33.05,33.05,0,0,0-14-3c-3.25,0-5.6.63-7.06,1.87a5.87,5.87,0,0,0-2.19,4.63,5.68,5.68,0,0,0,2.19,4.44,38.37,38.37,0,0,0,7.81,4.56,154.87,154.87,0,0,1,16,8.43,31.63,31.63,0,0,1,9.41,8.66A20.91,20.91,0,0,1,64,62.62Z"
      />
    </svg>
  );
}

export default Logo;
