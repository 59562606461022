import { usePDF } from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReportDocument from './ReportDocument';

function OpenPdf({ label, ga4 }) {
  const { search } = useLocation();
  const { id, domain } = useParams();
  const [instance] = usePDF({
    document: <ReportDocument id={id} domain={domain} search={search} ga4={ga4} />,
  });
  const { loading, url } = instance;

  return (
    <a
      href={url}
      target="_blank"
      className="btn btn-primary"
      rel="noreferrer"
      style={{ fontSize: '1.1em' }}
    >
      {loading ? (
        <span className="spinner-border spinner-border-sm me-1" />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="25"
          fill="currentColor"
          className="bi bi-file-earmark-arrow-down-fill"
          viewBox="4 0 12 20"
        >
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
        </svg>
      )}
      {label}
    </a>
  );
}

OpenPdf.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ga4: PropTypes.object,
  label: PropTypes.string.isRequired,
};

OpenPdf.defaultProps = {
  ga4: undefined,
};

export default OpenPdf;
