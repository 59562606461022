import React from 'react';
import { capitalCase } from 'change-case';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

import Circle from '../Circle';
import Metrics from '../Metrics';

function calculateLevel(score) {
  if (score.dmarcValid === 0) return Circle.LEVELS.DANGER;
  if (score.spfValid === 0) return Circle.LEVELS.DANGER;
  if (score.dmarcPolicy === 1) return Circle.LEVELS.WARNING;
  if (score.dmarcPolicy === 3) return Circle.LEVELS.GOOD;
  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <p>{t('sections.dmarcSection.content.description.dangerLevel.textNoDmarc')}</p>
          <p>
            {t('sections.dmarcSection.content.description.dangerLevel.textgoodNews')}{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/our-company/blogs-news/blog/email-comes-of-age-with-dmarc/"
            >
              {t('sections.dmarcSection.content.description.dangerLevel.linkInfo')}
            </a>
          </p>
        </div>
      );
    case Circle.LEVELS.WARNING:
      return (
        <div>
          <p>
            {t('sections.dmarcSection.content.description.warningLevel.textBegunDmarc')}
          </p>
        </div>
      );
    case Circle.LEVELS.GOOD:
      return (
        <p>
          {t('sections.dmarcSection.content.description.goodLevel.textWellDoneDmarc')}
        </p>
      );
    default:
      return (
        <p>
          {`${t('sections.dmarcSection.content.description.noDescription')} ${level}`}
        </p>
      );
  }
}

function Dmarc(props) {
  const { score, record, warnings, error, tags } = props;
  const { t } = useTranslation('reportComponent');
  const { t: translateMetrics } = useTranslation('metricsComponent');

  const level = calculateLevel(score);
  const description = getLevelDescription(level, t);

  /* TODO too much vague, unsafe business logic here, should be on server side */
  const metrics = [
    {
      label: translateMetrics('labels.dmarcValid'),
      level: score.dmarcValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value:
        score.dmarcValid === 1
          ? translateMetrics('values.yes')
          : translateMetrics('values.no'),
    },
    {
      label: translateMetrics('labels.spfValid'),
      level: score.spfValid === 1 ? Circle.LEVELS.GOOD : Circle.LEVELS.DANGER,
      value:
        score.spfValid === 1
          ? translateMetrics('values.yes')
          : translateMetrics('values.no'),
    },
  ];

  if (tags?.p) {
    metrics.push({
      label: translateMetrics('labels.dmarcPolicy'),
      level: tags.p.value === 'none' ? Circle.LEVELS.WARNING : Circle.LEVELS.GOOD,
      value: capitalCase(translateMetrics(`values.${tags.p.value}`)),
    });
  } else {
    metrics.push({
      label: translateMetrics('labels.dmarcPolicy'),
      level: Circle.LEVELS.WARNING,
      value: translateMetrics('values.no'),
    });
  }
  if (tags?.rua) {
    metrics.push({
      label: translateMetrics('labels.dmarcReporting'),
      level: Circle.LEVELS.GOOD,
      value: translateMetrics('values.yes'),
    });
  } else {
    metrics.push({
      label: translateMetrics('labels.dmarcReporting'),
      level: Circle.LEVELS.WARNING,
      value: translateMetrics('values.no'),
    });
  }

  return (
    <section>
      <p>{t('sections.dmarcSection.content.textDmarcStopsSpoofing')}</p>
      <Card body border="light" bg="light">
        <Row>
          <Col xs={12} sm={4}>
            <Circle title="DMARC" level={level} />
          </Col>
          <Col xs={12} sm={8}>
            {description}
          </Col>
        </Row>
      </Card>
      {record && (
        <p className="mt-3">
          <strong>{t('sections.dmarcSection.content.recordTitle')}</strong>
          &nbsp;
          {record}
        </p>
      )}
      {error && (
        <p className="mt-3">
          <strong>{t('sections.dmarcSection.content.errorTitle')}</strong>
          &nbsp;
          {error}
        </p>
      )}
      {warnings?.length > 0 && (
        <p className="mt-3">
          <strong>{t('sections.dmarcSection.content.warningsTitle')}</strong>
          &nbsp;
          {warnings.join(', ')}
        </p>
      )}
      <Metrics list={metrics} />
    </section>
  );
}

Dmarc.propTypes = {
  score: PropTypes.objectOf(PropTypes.number).isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.string,
  error: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.object,
};

Dmarc.defaultProps = {
  record: undefined,
  warnings: undefined,
  error: undefined,
  tags: undefined,
};

Dmarc.calculateLevel = calculateLevel;

export default Dmarc;
