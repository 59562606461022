import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import GA4React from 'ga-4-react';

import App from './pages/App';
import './fonts/Avenir.ttc';
import './index.scss';

import './lib/translation';

const ga4react = new GA4React(process.env.GA_MEASUREMENT_ID, {
  debug_mode: !process.env.production,
});

(async () => {
  let ga4;
  try {
    ga4 = await ga4react.initialize();
  } catch (err) {
    // at some stage maybe log an insight
    ga4 = {};
  }

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback="Loading...">
          <App ga4={ga4} />
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>
  );
})();
