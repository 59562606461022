import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import axios from 'axios';

import validationSchema from '../helper/ValidationHelper';
import Title from '../components/Title';

function Register() {
  const [message, setMessage] = useState('');
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const { t } = useTranslation('registerPage');

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    acceptTerms: false,
    acceptContact: false,
    recaptcha: '',
  };

  const resetRecaptcha = () => {
    recaptchaRef.current.reset();
    setVerified(false);
  };

  const handleSubmit = async ({
    firstName,
    lastName,
    email,
    role,
    acceptTerms,
    acceptContact,
    recaptcha,
  }) => {
    if (!recaptcha) {
      return setMessage('Please verify Recaptcha!');
    }
    try {
      const response = await axios.post('/api/HttpRequestCreate', {
        input: {
          firstName,
          lastName,
          email,
          role,
          acceptTerms,
          acceptContact,
          recaptcha,
        },
      });
      resetRecaptcha();
      setMessage('Please! Check your inbox for report email');
      navigate('/success');
      return response;
    } catch (error) {
      resetRecaptcha();
      return setMessage('Something went wrong. Please try again!');
    }
  };

  return (
    <div className="col-md-12 p-4">
      <Title />
      <h4>{t('header')}</h4>
      <section className="intro">
        <p>{t('encourageSection')}</p>
      </section>
      <div className="card card-container p-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <div className="form-group row my-4">
                <label className="col-sm-4 col-form-label" htmlFor="firstName">
                  {t('form.labels.firstName')}
                </label>
                <div className="col-sm-8">
                  <Field
                    className="form-control"
                    type="text"
                    name="firstName"
                    id="firstName"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="p-1 m-0 alert alert-warning error-message"
                  />
                </div>
              </div>
              <div className="form-group row my-4">
                <label className="col-sm-4 col-form-label" htmlFor="lastName">
                  {t('form.labels.lastName')}
                </label>
                <div className="col-sm-8">
                  <Field
                    className="form-control"
                    type="text"
                    name="lastName"
                    id="lastName"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="p-1 m-0 alert alert-warning error-message"
                  />
                </div>
              </div>
              <div className="form-group row my-4">
                <label className="col-sm-4 col-form-label" htmlFor="role">
                  {t('form.labels.rolePosition')}
                </label>
                <div className="col-sm-8">
                  <Field className="form-control" type="text" name="role" id="role" />
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="p-1 m-0 alert alert-warning error-message"
                  />
                </div>
              </div>
              <div className="form-group row my-4">
                <label className="col-sm-4 col-form-label" htmlFor="email">
                  {t('form.labels.email')}
                </label>
                <div className="col-sm-8">
                  <Field className="form-control" type="text" name="email" id="email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="p-1 m-0 alert alert-warning error-message"
                  />
                </div>
              </div>
              <div className="form-group mt-4">
                <Field
                  className="form-check-input me-3"
                  type="checkbox"
                  name="acceptTerms"
                  id="acceptTerms"
                />
                <label className="form-check-label" htmlFor="acceptTerms">
                  {t('form.labels.agreeT&Cs')}
                </label>
                <a href="Home" className="ms-4">
                  {t('form.labels.t&Cs')}
                </a>
                <ErrorMessage
                  name="acceptTerms"
                  component="div"
                  className="p-1 m-1 alert alert-warning error-message"
                />
              </div>
              <div className="form-group mt-4">
                <Field
                  className="form-check-input me-3"
                  type="checkbox"
                  name="acceptContact"
                  id="acceptContact"
                />
                <label className="form-check-label" htmlFor="acceptContact">
                  {t('form.labels.agreeContact')}
                </label>
                <ErrorMessage
                  name="acceptContact"
                  component="div"
                  className="p-1 m-1 alert alert-warning error-message"
                />
              </div>
              <div className="form-group mt-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  name="recaptcha"
                  id="recaptcha"
                  sitekey={process.env.RECAPTCHA_CLIENT_KEY}
                  onChange={(value) => {
                    setFieldValue('recaptcha', value);
                    setVerified(true);
                  }}
                  onExpired={resetRecaptcha}
                />
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="p-1 m-1 alert alert-warning error-message"
                />
              </div>
              <div className="form-group my-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={
                    !verified ||
                    isSubmitting ||
                    !!(errors.firstName && touched.firstName) ||
                    !!(errors.lastName && touched.lastName) ||
                    !!(errors.email && touched.email) ||
                    !!(errors.role && touched.role) ||
                    !!(errors.acceptTerms && touched.acceptTerms) ||
                    !!(errors.acceptContact && touched.acceptContact)
                  }
                >
                  {isSubmitting && <span className="spinner-border spinner-border-sm" />}
                  <span>{t('form.buttonLabel')}</span>
                </button>
              </div>
              {message && (
                <div className="form-group my-4">
                  <div className="p-1 m-1 alert alert-warning" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Register;
