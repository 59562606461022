import React from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import Circle from './Circle';
import Styles from '../Styles';

const TableStyles = Styles.table;

function Metrics({ list }) {
  const metricItemsTable = list.map((metric, i) => (
    <View style={TableStyles.tableRow} key={metric.label}>
      <View style={TableStyles.tableCol}>
        <View style={TableStyles.tableCell}>
          <Circle level={metric.level} radius={8} />
        </View>
      </View>
      <View style={TableStyles.tableCol}>
        <Text style={TableStyles.tableCell}>{metric.label}</Text>
      </View>
      <View style={TableStyles.tableCol}>
        <Text style={TableStyles.tableCell}>{metric.value}</Text>
      </View>
    </View>
  ));
  return (
    <>
      <Text style={Styles.bold}>Metrics:</Text>
      <View style={TableStyles.table}>{metricItemsTable}</View>
    </>
  );
}

Metrics.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
};

export default Metrics;
