import { StyleSheet, Font } from '@react-pdf/renderer';
import Avenir from './assets/AvenirNext.ttf';

const $levelGood = '#7bbd40';
const $levelWarning = '#efb54c';
const $levelDanger = '#df362d';
const $secondary = '#a59191';
const $dark = '#333333';
const $gray = '#f2f2f2';
const $light = '#f8f9fa';
const $blueTitle = '#345CD9';
const $blueBackground = '#DBF1FD';
const $primary = '#303cbf';

// const $cyan= '#4dbbff';
// const $blue= $primary;
// const $green= '#1abc9c';
// const $yellow= '#f1c40f';
// const $red= '#c0392b';

Font.register({
  family: 'AvenirNext',
  format: 'truetype',
  src: Avenir,
});

const Styles = StyleSheet.create({
  document: {
    width: '100%',
    fontFamily: 'AvenirNext',
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  view: {
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
    marginTop: 10,
    fontSize: 14,
  },
  leftMargin: {
    marginLeft: 18,
  },
  bigTitle: {
    fontSize: 24,
    fontFamily: 'AvenirNext',
  },
  title: {
    fontSize: 18,
    fontFamily: 'AvenirNext',
    marginBottom: 5,
  },
  text: {
    fontSize: 14,
    fontFamily: 'AvenirNext',
  },
  smallText: {
    fontSize: 12,
    fontFamily: 'AvenirNext',
  },
  bold: {
    fontWeight: 'black',
    marginTop: 5,
  },
  blueBackground: {
    color: $blueTitle,
    backgroundColor: $blueBackground,
    padding: 5,
  },
  link: {
    fontSize: 18,
    fontFamily: 'AvenirNext',
    textDecoration: 'none',
    color: $dark,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  flexContainer: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: $light,
  },
  flexSectionRight: {
    padding: 5,
    width: '68%',
  },
  flexSectionLeft: {
    width: '25%',
    height: '100%',
  },
  metrics: {
    ul: {
      columns: 2,
      listStyle: 'none',
      padding: '0',
      li: {
        borderTop: `1px $gray-300 solid`,
        before: {
          width: '10px',
          height: '10px',
          display: 'inline-block',
          content: '',
        },

        em: {
          float: 'right',
          fontStyle: 'normal',
        },
        good: {
          before: {
            borderRadius: '50%',
            backgroundColor: $levelGood,
          },

          em: {
            color: $levelGood,
          },
        },
        warning: {
          before: {
            backgroundColor: $levelWarning,
          },
          em: {
            color: $levelWarning,
          },
        },
        danger: {
          before: {
            backgroundColor: $levelDanger,
          },
          em: {
            color: $levelDanger,
          },
        },
      },
    },
  },
  gauge: {
    page: {
      padding: 60,
    },
    wrapper: {
      width: 200,
      height: 220,
    },
    meter: {
      width: '100%',
      height: '100%',
    },
    outLineDanger: {
      strokeWidth: '40',
      stroke: $levelDanger,
      fill: 'none',
      strokeDasharray: '236, 943',
    },
    outLineWarning: {
      strokeWidth: '40',
      stroke: $levelWarning,
      fill: 'none',
      strokeDasharray: '354, 943',
    },
    outLineGood: {
      strokeWidth: '40',
      stroke: $levelGood,
      fill: 'none',
      strokeDasharray: '471, 943',
    },
    outLineGray: {
      strokeWidth: '50',
      stroke: $gray,
      fill: 'none',
      strokeDasharray: '471, 943',
    },
    outLineEnds: {
      strokeWidth: '50',
      stroke: $gray,
      fill: 'none',
      strokeDasharray: '2, 469',
    },
    needle: {
      width: '6%',
      height: '35%',
      left: '47%',
      margin: 'auto',
      position: 'absolute',
      right: '0%',
      top: '22%',
      transformOrigin: 'bottom center',
      transform: 'rotate(0deg)',
    },
    percentage: {
      fontSize: 48,
      fontFamily: 'AvenirNext',
    },
  },
  circle: {
    circle: {
      display: 'flex',
      borderRadius: '50%',
      margin: 'auto',
      backgroundColor: 'green',
    },
    title: {
      margin: 'auto',
    },
    good: {
      backgroundColor: $levelGood,
    },

    warning: {
      backgroundColor: $levelWarning,
    },

    danger: {
      backgroundColor: $levelDanger,
    },

    undefined: {
      backgroundColor: `rgba(${$secondary}, 0.2)`,
    },
  },
  table: {
    body: {
      padding: 10,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderColor: '#bfbfbf',
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableCol: {
      width: '33%',
      borderStyle: 'solid',
      borderColor: $gray,
      borderWidth: 1.5,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableCell: {
      margin: 3,
      fontSize: 12,
    },
  },
  badge: {
    alignSelf: 'flex-start',
    backgroundColor: $primary,
    color: 'white',
    borderRadius: '4',
    fontSize: '1.1rem',
    fontWeight: '900',
    padding: '4',
    display: 'inline-block',
    marginLeft: 'auto',
  },
  badgeTitle: {
    fontSize: 18,
    fontFamily: 'AvenirNext',
    margin: '2',
  },
});

export default Styles;
