import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Success() {
  const { t } = useTranslation('successPage');
  return (
    <div className="text-center py-4 my-4">
      <h3>{t('successMessage')}</h3>
      <Link to="/">{t('linkBackHome')}</Link>
    </div>
  );
}

export default Success;
