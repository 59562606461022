import {
  commonEn,
  commonFr,
  titleComponentEn,
  titleComponentFr,
  reportComponentEn,
  reportComponentFr,
  circleComponentEn,
  circleComponentFr,
  detailWrapperComponentEn,
  detailWrapperComponentFr,
  metricsComponentEn,
  metricsComponentFr,
  registerPageEn,
  registerPageFr,
  detailNewPageEn,
  detailNewPageFr,
  detailPageEn,
  detailPageFr,
  missingPageEn,
  missingPageFr,
  noMatchPageEn,
  noMatchPageFr,
  reportPageEn,
  reportPageFr,
  SuccessPageEn,
  SuccessPageFr,
} from './translations';

const resources = {
  en: {
    common: commonEn,
    detailNewPage: detailNewPageEn,
    detailPage: detailPageEn,
    missingPage: missingPageEn,
    noMatchPage: noMatchPageEn,
    reportPage: reportPageEn,
    SuccessPage: SuccessPageEn,
    titleComponent: titleComponentEn,
    reportComponent: reportComponentEn,
    circleComponent: circleComponentEn,
    detailWrapperComponent: detailWrapperComponentEn,
    metricsComponent: metricsComponentEn,
    registerPage: registerPageEn,
  },
  fr: {
    common: commonFr,
    detailNewPage: detailNewPageFr,
    detailPage: detailPageFr,
    missingPage: missingPageFr,
    noMatchPage: noMatchPageFr,
    reportPage: reportPageFr,
    SuccessPage: SuccessPageFr,
    titleComponent: titleComponentFr,
    reportComponent: reportComponentFr,
    circleComponent: circleComponentFr,
    detailWrapperComponent: detailWrapperComponentFr,
    metricsComponent: metricsComponentFr,
    registerPage: registerPageFr,
  },
};

export default resources;
