import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import Styles from '../Styles';

function Badge({ domain }) {
  return (
    <View style={Styles.badge}>
      <Text style={Styles.badgeTitle}>{domain}</Text>
    </View>
  );
}

Badge.propTypes = {
  domain: PropTypes.string.isRequired,
};

export default Badge;
