import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Text, View, Link } from '@react-pdf/renderer';
import Styles from '../Styles';

import Circle from '../components/Circle';
import Gauge from '../components/Gauge';
import Dmarc from './Dmarc';
import Provider from './Provider';
import Spf from './Spf';
import Tls from './Tls';

function calculateLevel(final) {
  const overallGaugePercentage = final / 100;

  if (overallGaugePercentage <= 0.5) {
    return Circle.LEVELS.DANGER;
  }

  if (overallGaugePercentage <= 0.75) {
    return Circle.LEVELS.WARNING;
  }

  if (overallGaugePercentage > 0.75) {
    return Circle.LEVELS.GOOD;
  }

  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <View>
          <Text style={Styles.title}>
            {t('sections.introSection.content.description.dangerLevel.title')}
          </Text>
          <Text style={Styles.smallText}>
            {t('sections.introSection.content.description.dangerLevel.textInRed')}
          </Text>
          <Text style={Styles.smallText}>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              {t('sections.introSection.content.description.dangerLevel.linkHelp')}{' '}
            </Link>
          </Text>
        </View>
      );
    case Circle.LEVELS.WARNING:
      return (
        <View>
          <Text style={Styles.title}>
            {t('sections.introSection.content.description.warningLevel.title')}
          </Text>
          <Text style={Styles.smallText}>
            {t('sections.introSection.content.description.warningLevel.textInYellow')}
          </Text>
        </View>
      );
    case Circle.LEVELS.GOOD:
      return (
        <View>
          <Text style={Styles.title}>
            {t('sections.introSection.content.description.goodLevel.title')}
          </Text>
          <Text style={Styles.smallText}>
            {t('sections.introSection.content.description.goodLevel.textInGreen')}
          </Text>
        </View>
      );
    default:
      return (
        <Text style={Styles.text}>
          {`${t('sections.introSection.content.description.noDescription')} ${level}`}
        </Text>
      );
  }
}

function Intro({ report }) {
  const { t } = useTranslation('reportComponent');
  const level = calculateLevel(report.scores.total.final);
  const description = getLevelDescription(level, t);

  return (
    <View style={Styles.section}>
      <View style={Styles.section}>
        <Text style={Styles.title}>
          {t('sections.introSection.content.titleHowItStarted')}
        </Text>
        <Text style={Styles.text}>
          {t('sections.introSection.content.textEncourageDiscussion')}
        </Text>
      </View>
      <View style={Styles.section}>
        <Text style={Styles.title}>
          {t('sections.introSection.content.titleRiskReportFor')} {report.domain}
        </Text>
        <Text style={Styles.text}>
          {t('sections.introSection.content.textAnalysedDomain')}
        </Text>
        <View style={Styles.section}>
          <Text style={Styles.text}>
            <Link
              target="_blank"
              rel="noreferrer"
              href="https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/"
            >
              {t('sections.introSection.content.linkBookMeeting')}
            </Link>
          </Text>
        </View>
        <View style={{ ...Styles.section, ...Styles.flexContainer }}>
          <Gauge final={report.scores.total.final} />
          <View style={Styles.flexSectionRight}>{description}</View>
        </View>
      </View>
      <View style={Styles.section} break>
        <View style={Styles.section}>
          <Text style={Styles.title}>
            {t('sections.introSection.content.titleRiskBreakdown')}
          </Text>
          <Text style={Styles.text}>
            {t('sections.introSection.content.textRiskScore')}
          </Text>
        </View>
        <View style={Styles.flexContainer}>
          <Link href="#dmarcSection" style={Styles.link}>
            <Circle title="DMARC" level={Dmarc.calculateLevel(report.scores.dmarc)} />
          </Link>
          <View>
            <Link href="#spfSection" style={Styles.link}>
              <Circle title="SPF" level={Spf.calculateLevel(report.scores.spf)} />
            </Link>
          </View>
          <View>
            <Link href="#providerSection" style={Styles.link}>
              <Circle
                title="Provider"
                level={Provider.calculateLevel(report.scores.provider)}
              />
            </Link>
          </View>
          <View>
            <Link href="#tlsSection" style={Styles.link}>
              <Circle title="TLS" level={Tls.calculateLevel(report.scores.tls)} />
            </Link>
          </View>
        </View>
      </View>
    </View>
  );
}

Intro.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.object.isRequired,
};

Intro.calculateLevel = calculateLevel;

export default Intro;
