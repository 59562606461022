import { useTranslation } from 'react-i18next';
import { Text, Link, View } from '@react-pdf/renderer';
import Styles from '../Styles';

function Smx() {
  const { t } = useTranslation('reportComponent');
  return (
    <View style={Styles.leftMargin}>
      <View style={Styles.section}>
        <Text style={Styles.text}>{t('sections.smxSection.content.introduction')}</Text>
      </View>
      <View style={Styles.section}>
        <Text style={Styles.title}>
          {t('sections.smxSection.content.trustSection.header')}
        </Text>
        <Text style={Styles.text}>
          <Link target="_blank" rel="noreferrer" href="https://smxemail.com/our-company/">
            {t('sections.smxSection.content.trustSection.linkLabels.more')}
          </Link>
        </Text>
      </View>
      <View style={Styles.section}>
        <Text style={Styles.title}>
          {t('sections.smxSection.content.enhanceSection.header')}
        </Text>
        <Text style={Styles.text}>
          {t('sections.smxSection.content.enhanceSection.content.p1')}
        </Text>
        <Text style={Styles.text}>
          {t('sections.smxSection.content.enhanceSection.content.p2')}
        </Text>
        <Text style={Styles.text}>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://smxemail.com/media/byzjibji/datasheet-smx-365_august2021.pdf"
          >
            {t('sections.smxSection.content.enhanceSection.linkLabels.download')}{' '}
          </Link>
        </Text>
      </View>
      <View style={Styles.section}>
        <Text style={Styles.title}>
          {t('sections.smxSection.content.protectSection.header')}
        </Text>
        <Text style={Styles.text}>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/"
          >
            {t('sections.smxSection.content.protectSection.linkLabels.book')}{' '}
          </Link>
        </Text>
      </View>
    </View>
  );
}

export default Smx;
