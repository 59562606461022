import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'react-bootstrap';

import Circle from '../Circle';
import Gauge from '../Gauge';
import Dmarc from './Dmarc';
import Provider from './Provider';
import Spf from './Spf';
import Tls from './Tls';

function calculateLevel(final) {
  const overallGaugePercentage = final / 100;

  if (overallGaugePercentage <= 0.5) {
    return Circle.LEVELS.DANGER;
  }

  if (overallGaugePercentage <= 0.75) {
    return Circle.LEVELS.WARNING;
  }

  if (overallGaugePercentage > 0.75) {
    return Circle.LEVELS.GOOD;
  }

  return Circle.LEVELS.UNDEFINED;
}

function getLevelDescription(level, t) {
  switch (level) {
    case Circle.LEVELS.DANGER:
      return (
        <div>
          <h4>
            <p>{t('sections.introSection.content.description.dangerLevel.title')}</p>
          </h4>
          <p>
            <p>{t('sections.introSection.content.description.dangerLevel.textInRed')}</p>
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://smxemail.com/smx-365-contact-us/"
            >
              <p>{t('sections.introSection.content.description.dangerLevel.linkHelp')}</p>
            </a>
          </p>
        </div>
      );
    case Circle.LEVELS.WARNING:
      return (
        <div>
          <h4>{t('sections.introSection.content.description.warningLevel.title')}</h4>
          <p>
            {t('sections.introSection.content.description.warningLevel.textInYellow')}
          </p>
        </div>
      );
    case Circle.LEVELS.GOOD:
      return (
        <div>
          <h4>{t('sections.introSection.content.description.goodLevel.title')}</h4>
          <p>{t('sections.introSection.content.description.goodLevel.textInGreen')}</p>
        </div>
      );
    default:
      return (
        <p>
          {`${t('sections.introSection.content.description.noDescription')} ${level}`}
        </p>
      );
  }
}

function Intro(props) {
  const { t } = useTranslation('reportComponent');
  const { report } = props;
  const level = calculateLevel(report.scores.total.final);
  const description = getLevelDescription(level, t);

  return (
    <>
      <section className="intro">
        <h4>{t('sections.introSection.content.titleHowItStarted')}</h4>
        <p>{t('sections.introSection.content.textEncourageDiscussion')}</p>
      </section>
      <section className="intro">
        <h4>
          {t('sections.introSection.content.titleRiskReportFor')} {report.domain}
        </h4>
        <p>{t('sections.introSection.content.textAnalysedDomain')}</p>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/"
          >
            {t('sections.introSection.content.linkBookMeeting')}
          </a>
        </p>
        <Card body border="light" bg="light">
          <Container fluid>
            <Row className="justify-content-around">
              <Col xs={12} lg={6}>
                <Gauge final={report.scores.total.final} />
              </Col>
              <Col xs={12} lg={6}>
                {description}
              </Col>
            </Row>
          </Container>
        </Card>
      </section>
      <section className="intro" style={{ pageBreakBefore: 'always' }}>
        <h4 className="text-center mt-3">
          {t('sections.introSection.content.titleRiskBreakdown')}
        </h4>
        <p>{t('sections.introSection.content.textRiskScore')}</p>
        <Card body border="light" bg="light">
          <Container fluid>
            <Row className="justify-content-around circles">
              <Col className="text-center" xs={6} lg={3}>
                <a
                  href="#dmarcSection"
                  title={t('sections.introSection.content.circles.dmarcSectionLinkTitle')}
                >
                  <Circle
                    title="DMARC"
                    level={Dmarc.calculateLevel(report.scores.dmarc)}
                  />
                </a>
              </Col>
              <Col className="text-center" xs={6} lg={3}>
                <a
                  href="#spfSection"
                  title={t('sections.introSection.content.circles.spfSectionLinkTitle')}
                >
                  <Circle title="SPF" level={Spf.calculateLevel(report.scores.spf)} />
                </a>
              </Col>
              <Col className="text-center" xs={6} lg={3}>
                <a
                  href="#providerSection"
                  title={t(
                    'sections.introSection.content.circles.providerSectionLinkTitle'
                  )}
                >
                  <Circle
                    title="Provider"
                    level={Provider.calculateLevel(report.scores.provider)}
                  />
                </a>
              </Col>
              <Col className="text-center" xs={6} lg={3}>
                <a
                  href="#tlsSection"
                  title={t('sections.introSection.content.circles.tlsSectionLinkTitle')}
                >
                  <Circle title="TLS" level={Tls.calculateLevel(report.scores.tls)} />
                </a>
              </Col>
            </Row>
          </Container>
        </Card>
      </section>
    </>
  );
}

Intro.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.object.isRequired,
};

Intro.calculateLevel = calculateLevel;

export default Intro;
