import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Routes, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Logo from '../components/Logo';
// import LanguageSelect from '../components/LanguageSelect';

import Report from './Report';
import Register from './Register';
import Success from './Success';

function App({ ga4 }) {
  const { t } = useTranslation('common');

  return (
    <Container fluid="md">
      <Row>
        <Col md={2}>
          <Row className="justify-content-between justify-content-md-start">
            <Col xs={4} md={12}>
              <Logo />
            </Col>
            <Col xs={4} md={12} className="mt-md-4 mb-4 text-end text-md-start">
              <br />
              <a target="_blank" rel="noreferrer" href="https://smxemail.com/">
                {t('sidebar.linkLabels.home')}
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://smxemail.com/smx-for-enterprise/smx-365/"
              >
                {t('sidebar.linkLabels.smx365')}
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://smxemail.com/our-company/"
              >
                {t('sidebar.linkLabels.about')}
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://smxemail.com/smx-365-contact-us/"
              >
                {t('sidebar.linkLabels.contact')}
              </a>
              <div className="d-none d-md-block mt-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://outlook.office365.com/owa/calendar/SMXLimitedEmailSecuritySpecialists@smxemail.com/bookings/"
                >
                  {t('sidebar.linkLabels.bookMeeting')}
                </a>
              </div>
              <div className="d-none d-md-block mt-4">
                <Link rel="noreferrer" to="start">
                  {t('sidebar.linkLabels.accessRiskReport')}
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={1} className="side-wrapper d-md-block" />
        <Col md={{ span: 9, offset: 0 }}>
          {/* <Row className="mb-4 mt-4 justify-content-end">
            <Col sm={2}>
              <LanguageSelect />
            </Col>
          </Row> */}
          <Routes>
            <Route path="/report/:id" element={<Report ga4={ga4} />} />
            <Route path="/reportdomain/:domain" element={<Report ga4={ga4} />} />
            <Route path="/start" element={<Register />} />
            <Route path="/success" element={<Success />} />
            <Route path="*" element={<Report />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ga4: PropTypes.object,
};

App.defaultProps = {
  ga4: undefined,
};

export default App;
