import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import { Document, Page, Text } from '@react-pdf/renderer';
import Styles from './Styles';
import Report from './Report';

const base64Encode = (str) => Base64.stringify(Utf8.parse(str));
const base64Decode = (base64) => Base64.parse(base64).toString(Utf8);

function ReportDocument({ ga4, search, id, domain }) {
  const { t } = useTranslation('reportPage');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);
  // console.log('In document', id, domain, search);

  useDeepCompareEffect(() => {
    async function getReport() {
      let reportId = id;
      try {
        setLoading(true);
        if (domain) {
          reportId = base64Encode(domain);
        }
        const response = await axios.get(`/api/HttpReportShow?report-id=${reportId}`);
        setReport(response.data);
        if (ga4?.gtag) {
          let gaCategory = 'found';
          if (Object.keys(response.data).length < 1) {
            gaCategory = 'notfound';
          }
          ga4.gtag('event', 'view_item', {
            items: [
              {
                item_name: base64Decode(reportId),
                item_category: gaCategory,
              },
            ],
          });
        }
      } catch (exc) {
        if (ga4?.gtag) {
          ga4.gtag('event', 'view_item', {
            items: [
              {
                item_name: base64Decode(reportId),
                item_category: 'exception',
              },
            ],
          });
        }
        setError(exc);
      } finally {
        setLoading(false);
      }
    }
    if (id || domain) {
      getReport();
    } else {
      if (ga4?.gtag) {
        ga4.gtag('event', 'view_item', {
          items: [
            {
              item_name: 'empty',
              item_category: 'empty',
            },
          ],
        });
      }
      setLoading(false);
      setReport(null);
    }
  }, [ga4, id, domain]);

  let content;

  if ((id || domain) && !error && !loading && Object.keys(report).length >= 1) {
    content = <Report report={report} />;
  } else {
    content = <Text>{t('noInfoMessage')}</Text>;
  }

  return (
    <Document style={Styles.document}>
      <Page size="A4" style={Styles.page}>
        {content}
        <Text
          style={Styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}
ReportDocument.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ga4: PropTypes.object,
  search: PropTypes.string,
  id: PropTypes.string,
  domain: PropTypes.string,
};

ReportDocument.defaultProps = {
  ga4: undefined,
  search: '',
  id: undefined,
  domain: undefined,
};

export default ReportDocument;
